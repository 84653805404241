import React from "react";
import FeatherIcon from "feather-icons-react";

function Newsletter() {
  // const [mailData, setmailData] = useState({
  //   buttonDisabled: true,
  //   message: { fromEmail: "", subject: "", body: "" },
  //   submitting: false,
  //   error: null,
  // });
  // const getformData = (event) => {
  //   const name = event.target.getAttribute("name");
  //   setmailData({
  //     message: { ...this.state.message, [name]: event.target.value },
  //   });
  // };
  return (
    <section id="Contact">
      <div className="max-w-6xl mt-3 py-20 mx-auto px-4 sm:px-6">
        <div className="pb-12 md:py-20">
          {/* CTA box */}
          <div
            className="relative bg-white rounded-lg py-10 px-4 md:py-12 md:px-12 shadow-xl overflow-hidden"
            data-aos="zoom-y-out"
          >
            <div
              className="absolute right-0 bottom-0 pointer-events-none hidden lg:block"
              aria-hidden="true"
            />

            <div className="relative flex flex-col lg:flex-row justify-between items-center">
              {/* CTA content */}
              <section className="md:col-span-8 lg:col-span-8 col-span-12 mb-8">
                <div className="text-center lg:text-left lg:max-w-xl">
                  <h2 className="h2 mb-2">Looking to scale your idea?</h2>
                  <p className="text-gray-400 text-lg">
                    let&apos;s have a conversation
                  </p>
                </div>
              </section>
              {/* CTA form */}
              <section className="md:col-span-4 lg:col-span-4 col-span-12">
                <div className="flex flex-row sm:flex-row justify-center">
                  <a
                    href="mailto:pkshyamkrishna@gmail.com"
                    className="px-16 flex flex-col items-center justify-center border-r-4"
                  >
                    <div className="p-4 mb-2 rounded-full text-white bg-teal-600">
                      <FeatherIcon
                        icon="mail"
                        className=""
                        style={{ height: "40px", width: "40px" }}
                      ></FeatherIcon>
                    </div>
                    <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                      Email
                    </h4>
                  </a>
                  <a
                    href="tel:+91994176581"
                    className="px-16 flex flex-col items-center justify-center"
                  >
                    <div className="p-4 mb-2 rounded-full text-white bg-teal-600">
                      <FeatherIcon
                        icon="phone"
                        style={{ height: "40px", width: "40px" }}
                      ></FeatherIcon>
                    </div>
                    <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                      Phone
                    </h4>
                  </a>
                </div>
              </section>

              {/* <section className="w-full">
                <form className="w-full" method="post">
                  <div className="flex flex-col sm:flex-col justify-center">
                    <div className="flex flex-row mb-4">
                      <input
                        type="email"
                        className="w-full flex-grow appearance-none bg-gray-100 border border-gray-300 focus:border-purple-600 focus:bg-white rounded px-4 py-3 placeholder-gray-500"
                        placeholder="Your email…"
                        aria-label="Your email…"
                        value={mailData.message.fromEmail}
                        onChange={(e) => getformData(e)}
                      />
                      <a
                        className="btn text-white bg-teal-600 hover:bg-teal-700 shadow ml-4"
                        href="#0"
                      >
                        Enquire
                      </a>
                    </div>
                    <textarea
                      rows="3"
                      placeholder="Enter Your Message"
                      value={mailData.message.body}
                      onChange={(e) => getformData(e)}
                      className="w-full appearance-none bg-gray-100 border border-gray-300 focus:border-purple-600 focus:bg-white rounded px-4 py-3 mb-2 sm:mb-0 sm:mr-2 placeholder-gray-500"
                    ></textarea>
                  </div>
                  <p className="text-sm text-gray-400 mt-3">
                    Thanks for subscribing!
                  </p>
                  <p className="text-sm text-purple-400 mt-3">
                    You will hear from me in 24 hours.
                  </p>
                </form>
              </section> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
