import React from "react";
import Image from "./Image";
// import Modal from '../utils/Modal';

function Firstfold() {
  const dataConfig = [
    {
      titletag: "Scale Your Product\ns\n",
      title: "Customer Experience",
      btngrp: [
        {
          type: "primary",
          text: "View My Work",
          link: "",
        },
        {
          type: "secondary",
          text: "Understand The Process",
          link: "",
        },
      ],
      byline:
        '<span role="img" className="text-2xl"  aria-labelledby="wave">👋</span> Hello there!, I am <b>Shyamkrishnan</b> a <b>UX Engineer / Product Designer</b> focused on scaling <b>startup ideas</b> through <b>design thinking</b>',
      image: "",
    },
  ];

  const Title = (dataConfig) => {
    return (
      <h1
        className="text-5xl md:text-6xl leading-tighter tracking-tighter mb-4"
        data-aos="zoom-y-out"
      >
        <span className="">{dataConfig.titletag}</span>
        <span className="bg-clip-text font-bold text-transparent bg-gradient-to-r from-blue-500 to-pink-400">
          {dataConfig.title}
        </span>
      </h1>
    );
  };

  const Byline = (dataConfig) => {
    return (
      <div className="max-w-3xl mx-auto">
        <p
          className="text-xl text-gray-600 mb-8"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          {dataConfig.byline}
        </p>
      </div>
    );
  };

  const btnGrp = (dataConfig) => {
    return (
      <div
        className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
        data-aos="zoom-y-out"
        data-aos-delay="300"
      >
        {dataConfig.map((btn, index) => {
          return (
            <a
              key={index}
              href={btn.link}
              className="btn text-white rounded-full py-3 px-6 bg-teal-500 hover:bg-teal-600 w-full mb-4 sm:w-auto sm:mb-0"
            >
              {btn.text}
            </a>
          );
        })}
      </div>
    );
  };

  const createImage = (image) => {
    return (
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none"
        aria-hidden="true"
      >
        <img src={image} alt="Customer Experience" />
      </div>
    );
  };
  // const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section className="relative">
      {/* Illustration behind hero content */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="relative md:pt-32 pt-8 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div className="text-center pb-12 md:pb-20 z-10 relative">
            <h1
              className="text-5xl md:text-6xl leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out"
            >
              <span className="">Scale Your Product&apos;s </span>
              <span className="bg-clip-text font-bold text-transparent bg-gradient-to-r from-blue-500 to-pink-400">
                Customer Experience
              </span>
            </h1>
            <div className="max-w-3xl mx-auto pb-8">
              <p
                className="text-xl text-gray-600 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                <span role="img" className="text-2xl" aria-labelledby="wave">
                  👋
                </span>{" "}
                Hello there!, I am <b>Shyamkrishnan</b> a{" "}
                <b>UX Engineer / Product Designer</b> focused on scaling{" "}
                <b>startup ideas</b> through <b>design thinking</b>
              </p>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <div>
                  <a
                    className="btn text-white rounded-full py-3 px-6 bg-teal-500 hover:bg-teal-600 w-full mb-4 sm:w-auto sm:mb-0"
                    href="#Work"
                  >
                    View My Work
                  </a>
                </div>
                <div>
                  <a
                    className="btn shadow-none rounded-full py-3 px-6 border-1 border-teal-500  bg-teal-100 text-teal-600 hover:bg-teal-200 w-full sm:w-auto sm:ml-4"
                    href="#Contact"
                  >
                    Get In Touch
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute -bottom-3 left-0 w-full flex justify-center z-0">
            <Image className="w-full" src="hero_image.png" alt="hero image" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Firstfold;
