import React, { useState, useRef, useEffect, Fragment } from "react";
// import { graphql, useStaticQuery } from "gatsby";
// import Image from "./Image.js";
import Transition from "../utils/Transition.js";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import { useWorkHooks } from "../hooks/workhooks";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import FeatherIcon from "feather-icons-react";

function TabView(props) {
  const { errors } = props;
  const { nodes } = useWorkHooks();
  // const postNodes = work;
  // const filteredRecentWork = nodes.sort((item) => {
  //   return new Date() - new Date(item.publishedAt);
  // });
  const filteredRecentWork = nodes;
  const catagories = filteredRecentWork.map((items) => {
    const all = items.categories
      .map((item) => {
        return item.title;
      })
      .join();
    return all;
  });

  const filteredTab = catagories.filter((item, index) => {
    return catagories.indexOf(item) === index;
  });

  const [tab, setTab] = useState(1);
  const tabs = useRef(null);
  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height =
        tabs.current.children[tab - 1].offsetHeight + "px";
    }
  };
  useEffect(heightFix, [tab]);
  const TabButton = (item) => {
    return item.map((data, id) => {
      return (
        <a
          key={id}
          className={`flex w-full items-center text-lg p-2 transition duration-300  ease-in-out rounded-full ${
            tab !== id + 1
              ? "bg-trasparent hover:bg-blue-200"
              : "border-4 drop-shadow-md bg-white border-blue-100 z-10"
          }`}
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            setTab(id + 1);
          }}
        >
          <div
            className={`px-6 whitespace-nowrap capitalize  leading-snug tracking-tight w-full text-center ${
              tab !== id + 1 ? "font-regular opacity-60 hover:opacity-80" : "font-bold"
            }`}
          >
            {data}
          </div>
        </a>
      );
    });
  };

  const TabContent = (items, tabs) => {
    const filteredWork = (info, currentTab) => {
      return info.filter((item) => {
        return item.categories[0].title === currentTab;
      });
    };

    const tabContainer = tabs.map((data, id) => {
      const recentWork = filteredWork(items, data);
      return (
        <Transition
          key={id}
          show={tab === id + 1}
          appear={true}
          className="md:grid md:grid-cols-12 md:gap-6"
          enter="transition ease-in-out duration-700 transform order-first"
          enterStart="opacity-0 translate-y-16"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-in-out duration-300 transform absolute"
          leaveStart="opacity-100 translate-y-0"
          leaveEnd="opacity-0 -translate-y-16"
        >
          {recentWork.map((item) => {
            return (
              <a
                href={item.externalurl}
                target="_blank"
                className={
                  "md:col-span-4 lg:col-span-4 hover:scalediv text-gray-900 hover:text-indigo-600"
                }
                key={item._id}
                rel="noreferrer"
              >
                <div className="relative">
                <img
                  src={imageUrlFor(buildImageObj(item.mainImage))
                    .fit("crop")
                    .auto("format")
                    .url()}
                  alt={item.mainImage.alt}
                  className="md:w-full mx-auto rounded"
                />
                <div className="absolute bottom-0 right-0 backdrop-blur-lg p-3 bg-white"><span className=""><FeatherIcon icon={'arrow-up-right'} /></span></div>
                </div>
                <div className="py-2 mb-8 md:mb-0">
                  <p className="text-teal-600 uppercase font-semibold">
                    <small>
                      {item.domain.map((text) => {
                        return text;
                      })}
                    </small>
                  </p>
                  <p className="text-2xl capitalize">{item.title}</p>
                  <p className="pt-0 text-gray-600 capitalize">
                    {item.tag.map((text2,index) => {
                      return (index + 1) === item.tag.length ?`${text2}` : `${text2}, `;
                    })}
                  </p>
                </div>
              </a>
            );
          })}
        </Transition>
      );
    });
    return <Fragment>{tabContainer}</Fragment>;
  };
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  return (
    <div className="md:grid md:grid-cols-12 md:gap-6 md:mb-16" id="Work">
      <div
        className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-12 md:mt-6 overflow"
        data-aos="fade-right"
      >
        <div className="flex border-x-child w-auto bg-blue-100  rounded-full mb-4 md:mb-0 flex-nowrap overflow-auto">
          {TabButton(filteredTab)}
        </div>
      </div>
      <div
        className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-12 lg:col-span-12"
        data-aos="zoom-y-out"
        ref={tabs}
      >
        <div className="relative flex flex-col">
          {TabContent(filteredRecentWork, filteredTab)}
        </div>
      </div>
    </div>
  );
}

export default TabView;
