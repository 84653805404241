import React from "react";
import TabView from "./TabView";

function Features() {
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 bg-white pointer-events-none"
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 sm:pb-6">
        <div className="pt-12 md:pt-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-0">
            <h1 className="h2 mb-4">Recent Work</h1>
            <p className="text-xl text-gray-600">
              Some of my recent work spanning from product experience, product
              interface, few illustration as part of marketing and sales touch
              points along with personal projects.
            </p>
          </div>
          <TabView />
        </div>
      </div>
    </section>
  );
}

export default Features;
