import { useStaticQuery, graphql } from "gatsby";

export const useWorkHooks = () => {
  const { posts } = useStaticQuery(
    graphql`
      query WorkListQuery {
        posts: allSanityWork(sort: { fields: [publishedAt], order: DESC }) {
          nodes {
            _id
            title
            mainImage {
              ...SanityImage
              alt
            }
            publishedAt
            externalurl
            domain
            tag
            categories {
              title
            }
          }
        }
      }
    `
  );
  return posts;
};
