import React from "react";
import FeatherIcon from "feather-icons-react";

function FeaturesBlocks() {
  const userData = {
    title: "Services",
    byline:
      "The set of services I offer, cover end to end design consulting with focus on design thinking and running design operations @ scale",
    column: [
      {
        icon: "eye",
        color: "bg-teal-600",
        title: "Brand Idenitiy",
        byline: "Build a unique indentity for the brand/product",
      },
      {
        icon: "layout",
        color: "bg-teal-600",
        title: "Website Design",
        byline:
          "Refreshing and unique website design from design to implementation",
      },
      {
        icon: "figma",
        color: "bg-teal-600",
        title: "Product Design",
        byline:
          "Superiour product user experience through design thinking practices",
      },
      {
        icon: "command",
        color: "bg-teal-600",
        title: "Design Thinking",
        byline: "Conduct workshops to nuture product/design thinking culture",
      },
      {
        icon: "target",
        color: "bg-teal-600",
        title: "Design System",
        byline:
          "Build design guidelines and interface system for consistent design @ scale",
      },
      {
        icon: "code",
        color: "bg-teal-600",
        title: "Frontend Development",
        byline: "Build interface library for rapid frontend development",
      },
    ],
  };

  const createColumns = userData.column.map((data, index) => {
    return (
      <div
        key={index}
        className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl"
      >
        <div className={"p-3 mb-2 rounded-full text-white " + data.color}>
          <FeatherIcon icon={data.icon} />
        </div>
        <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
          {data.title}
        </h4>
        <p className="text-gray-600 text-center">{data.byline}</p>
      </div>
    );
  });
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-indigo-900 pointer-events-none"
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">{userData.title}</h2>
            <p className="text-xl text-gray-600">{userData.byline}</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-stretch md:max-w-2xl lg:max-w-none">
            {createColumns}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
